import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Section from "../../components/Section/Section";
import WorkListItem from "../../components/WorkListItem/WorkListItem";
import "./WorkList.scss";

const WorkList = ({ backgroundColour }) => {
  const [selectedFilter, setSelectedFilter] = useState("role");
  const [activeIndex, setActiveIndex] = useState(null);

  const { allWpSector, allWpDiscipline } = useStaticQuery(graphql`
    query SectorsQuery {
      allWpSector {
        nodes {
          databaseId
          name
          clients {
            nodes {
              title
              slug
              featuredImage {
                node {
                  altText
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              clientSide {
                year
                videoUrl
              }
              sectors {
                nodes {
                  name
                }
              }
            }
          }
          projectTaxonomy {
            image {
              altText
              publicUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      allWpDiscipline {
        nodes {
          databaseId
          name
          clients {
            nodes {
              title
              slug
              featuredImage {
                node {
                  altText
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
              sectors {
                nodes {
                  name
                }
              }
            }
          }
          projectTaxonomy {
            image {
              altText
              publicUrl
              localFile {
                extension
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    // Update locomotive scroll due to section height changes
    setTimeout(() => {
      window.scroll.update();
    }, 100);
  }, [selectedFilter]);
  return (
    <Section classes="work_list dynamic" backgroundColour={backgroundColour}>
      <div className="work_list__block row">
        <div className="work_list__column column">
          <div className="work_list__filter">
            <h2
              className={selectedFilter === "role" ? "active" : ""}
              onClick={() => {
                setSelectedFilter("role");
              }}
            >
              Sectors
            </h2>
            <h2
              className={selectedFilter === "type" ? "active" : ""}
              onClick={() => {
                setSelectedFilter("type");
              }}
            >
              Disciplines
            </h2>
          </div>
        </div>
        <div className="work_list__column column">
          <ul>
            {selectedFilter === "role"
              ? allWpSector.nodes.map(
                  ({ databaseId, name, clients, projectTaxonomy }, index) => (
                    <WorkListItem
                      key={databaseId}
                      index={databaseId}
                      name={name}
                      clients={clients}
                      image={projectTaxonomy}
                      activeIndex={activeIndex}
                      setActiveIndex={setActiveIndex}
                    />
                  )
                )
              : allWpDiscipline.nodes.map(
                  ({ databaseId, name, clients, projectTaxonomy }, index) => (
                    <WorkListItem
                      key={databaseId}
                      index={databaseId}
                      name={name}
                      clients={clients}
                      image={projectTaxonomy}
                      activeIndex={activeIndex}
                      setActiveIndex={setActiveIndex}
                    />
                  )
                )}
          </ul>
        </div>
      </div>
    </Section>
  );
};

export default WorkList;

