import React, { useState, useEffect } from "react";
import ChriateImage from "../../components/ChriateImage";
import { Link } from "gatsby";
import Section from "../../components/Section/Section";
import "./Footer.scss";

const Footer = ({ data }) => {
  const [speed, setSpeed] = useState("2.4");

  useEffect(() => {
    if (window?.innerWidth < 1024) {
      setSpeed("0");
    }
  }, []);

  return (
    <Section classes="footer" backgroundColour="#988f87">
      <div className="footer__row row">
        <div className="footer__column column">
          <div className="footer__link">
            <span>Next Story</span>
            <Link to="/brand-digital-studio" className="nav_link">
              <h2>Studio</h2>
            </Link>
          </div>

          <h1 className="footer__heading">S</h1>
        </div>
        <div className="footer__column column">
          <Link
            to="/brand-digital-studio"
            className="footer__image"
            data-scroll
            data-scroll-speed={0}
          >
            {data.edges[0]?.node?.featuredImage?.node && (
              <ChriateImage
                image={data.edges[0]?.node.featuredImage.node}
                alt={data.edges[0]?.node.featuredImage.node?.altText}
              />
            )}
          </Link>
          <h1 className="footer__heading">S</h1>
        </div>
      </div>
    </Section>
  );
};

export default Footer;

