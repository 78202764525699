import React, { useRef, useState, useEffect } from "react";
import ChriateImage from "../../components/ChriateImage";
import ArrowSVG from "../../images/svg/arrow-right.svg";
import "./WorkListItem.scss";

const SmallListItem = ({
  index,
  client,
  title,
  year,
  videoUrl,
  imageAlt,
  selectedItem,
  setSelectedItem,
}) => {
  return (
    <li
      key={index}
      onClick={() => {
        selectedItem === index ? setSelectedItem(null) : setSelectedItem(index);
      }}
      className={selectedItem === index ? "active" : ""}
    >
      {client.featuredImage?.node && !videoUrl ? (
        <ChriateImage
          onClick={() => {
            selectedItem === index
              ? setSelectedItem(null)
              : setSelectedItem(index);
          }}
          className={selectedItem === index ? "active" : ""}
          image={client.featuredImage?.node}
          alt={imageAlt.length ? imageAlt : ""}
        />
      ) : videoUrl ? (
        <video
          onClick={() => {
            selectedItem === index
              ? setSelectedItem(null)
              : setSelectedItem(index);
          }}
          className={selectedItem === index ? "active" : ""}
          src={videoUrl}
          muted
          playsInline
          autoPlay
          loop
        ></video>
      ) : (
        ""
      )}
      <div className="item">
        <span className="title">{title}</span>
        <span className="year">{year}</span>
      </div>
    </li>
  );
};

const WorkListItem = ({
  index,
  name,
  clients,
  image,
  activeIndex,
  setActiveIndex,
}) => {
  const listRef = useRef(0);
  const [listHeight, setListHeight] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    activeIndex === index
      ? setListHeight(listRef.current.offsetHeight)
      : setListHeight(0);
    setTimeout(() => {
      window.scroll.update();
    }, 500);
  }, [listRef, activeIndex]);

  return (
    <li className={`work_list__item${activeIndex === index ? " active" : ""}`}>
      <h2
        onClick={() => {
          setActiveIndex(index === activeIndex ? null : index);
        }}
      >
        <ArrowSVG />
        {name}
      </h2>
      <div className="work_list__item-list" style={{ maxHeight: listHeight }}>
        <ul ref={listRef}>
          {clients &&
            clients.nodes
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((client, index) => {
                const title = client.title;
                const { year, videoUrl } = client.clientSide;
                {
                  /* const type = Object.keys(client)[Object.keys(client).length - 1]; */
                }
                {
                  /* const info = client[type].nodes.filter(({ name }) => name !== 'Latest').map((item) => item.name).join(', '); */
                }
                const imageAlt = client.featuredImage?.node?.altText;

                return (
                  <SmallListItem
                    key={index}
                    index={index}
                    client={client}
                    title={title}
                    year={year}
                    videoUrl={videoUrl}
                    imageAlt={imageAlt}
                    activeIndex={activeIndex}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                  />
                );
              })}
        </ul>
      </div>
      {image ? (
        <ChriateImage
          className="work_list__item-image"
          image={image?.image}
          alt={image?.altText ? image.altText : ""}
        />
      ) : (
        ""
      )}
    </li>
  );
};

export default WorkListItem;

