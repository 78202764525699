import { graphql } from "gatsby";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Seo from "../components/seo";
import Nav from "../components/Nav/Nav";
import WorkFeed from "../modules/WorkFeed/WorkFeed";
import WorkList from "../modules/WorkList/WorkList";
import Section from "../components/Section/Section";
import Footer from "../modules/Footer/Footer";

const Filter = ({ data, setType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Latest");
  let types = data.nodes;
  types = types?.filter((type) => type.name !== "Latest");

  return (
    <Section classes={`work_feed__filter${isOpen ? " active" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Projects - Seesaw Studio</title>
      </Helmet>
      <span
        className="work_feed__filter-label"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        Filter
      </span>
      <div className="work_feed__filter-list">
        <span
          className={selectedFilter === "Latest" ? "active" : ""}
          data-type=""
          onClick={() => {
            setType("");
            setIsOpen(false);
            setSelectedFilter("Latest");
          }}
        >
          Latest
        </span>
        {types &&
          types.map(({ databaseId, name }) => {
            return (
              <span
                key={databaseId}
                className={selectedFilter === name ? "active" : ""}
                data-type={databaseId}
                onClick={() => {
                  setType(databaseId);
                  setIsOpen(false);
                  setSelectedFilter(name);
                }}
              >
                {name}
              </span>
            );
          })}
      </div>
    </Section>
  );
};

const ProjectPage = ({
  data: { wpPage, allWpProject, allWpType, allWpPage },
}) => {
  const [type, setType] = useState("");
  const {
    template: { projectsTemplate },
    slug,
    seo,
  } = wpPage;
  const { heading, text1, text2 } = projectsTemplate;

  return (
    <>
      <Seo seo={seo} />
      <Nav position="top" slug={slug} />
      <Filter data={allWpType} setType={setType} />
      {/* TODO: Move the content block outside so the filter can be placed below it. */}
      <WorkFeed
        heading={heading}
        text1={text1}
        text2={text2}
        gatsbyImages={allWpProject}
        type={type}
      />
      <WorkList gatsbyImages={allWpProject} type={type} />
      <Footer data={allWpPage} />
    </>
  );
};

export default ProjectPage;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      slug
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }

      template {
        ... on WpTemplate_ProjectsWork {
          templateName
          projectsTemplate {
            heading
            text1
            text2
          }
        }
      }
    }
    allWpProject {
      nodes {
        databaseId
        featuredImage {
          node {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    allWpType {
      nodes {
        databaseId
        name
      }
    }
    allWpPage(filter: { slug: { eq: "studio" } }) {
      edges {
        node {
          slug
          title
          wpParent {
            node {
              id
            }
          }
          wpChildren {
            nodes {
              id
            }
          }
          featuredImage {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

